.tableUI {
    table {
        width: 100%;
        border-spacing: 0;
    }

    tr th,
    tr td {
        font-size: 0.95em;
        padding: 15px 10px;
        cursor: default;
    }

    tr th {
        color: #616168;
        font-weight: 500;
        text-align: left;
    }

    th:not(:last-child),
    td:not(:last-child) {
        padding-right: 10px;
    }
}

.tableUIMobile {
    tr th,
    tr td {
        padding: 12px 5px;
    }
}

.tableUISimple {
    tbody tr:nth-child(odd) {
        background: #2d2e36;
    }

    tr td:first-child {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }
    tr td:last-child {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
    }
}

.tableUIWithBorder {
    table {
        border-collapse: collapse;
    }

    td {
        border: 1px solid #373740;
    }
}
