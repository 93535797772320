.clockWrap {
    display: flex;
    align-items: center;
}

.clockWrapWide {
    width: 100%;

    .clockItem {
        flex: 1;
    }
}

.clockItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
}

.clockValue {
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom: 3px;
}

.clockTitle {
    //color: #616168;
    font-size: 0.75em;
    font-weight: 500;
}

.clockSeprator {
    font-weight: bold;
}
