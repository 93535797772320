.actionWrap {
    display: flex;
    align-items: center;
}

.actionWrapStart {
    flex-direction: row-reverse;
}

.copyButton {
    color: #616168;
    margin-right: 7px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        color: inherit;
    }
}