.wrap {
    background: #201e26;
    padding: 15px;
    border-radius: 15px;
    border: 1px solid #2d2d34;
    transition: all 0.2s ease-out;
}

.wrap:hover {
    border-color: #35353a;
}

.message {
    width: 100%;
    font-size: 0.85em;
    font-weight: 500;
    text-align: center;
    color: #696972;
    padding: 25px 15px;
}
