/* Price */
.priceWrap {
    display: flex;
}

.priceWrapTop {
    flex-direction: column-reverse;
    align-items: flex-start;

    .priceItem:not(:first-child) {
        margin-bottom: 2px;
    }
}

.priceWrapBottom {
    flex-direction: column;
    align-items: flex-start;

    .priceItem:not(:last-child) {
        margin-bottom: 2px;
    }
}

.priceWrapStart {
    flex-direction: row-reverse;
    align-items: center;

    .priceItem:not(:first-child) {
        margin-right: 10px;
    }
}

.priceWrapEnd {
    flex-direction: row;
    align-items: center;

    .priceItem:not(:last-child) {
        margin-right: 10px;
    }
}

/* Price Unit */
.priceUnit {
    display: flex;
    align-items: baseline;
}

.priceUnitCrossedOut {
    position: relative;
    color: var(--title-sub);
    font-size: 0.85em;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 7px;
        width: 100%;
        height: 1px;
        background: rgba(97, 97, 104, 0.5);
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 6px;
        width: 100%;
        height: 1px;
        background: rgb(30 29 36 / 50%);
    }

    .priceSuffix {
        color: inherit;
    }
}

.priceNegative {
    color: #6a9370;
}

.priceSymbol {
    margin-left: 5px;
}

.priceSymbolSub {
    color: #636172;
    font-size: 0.75em;
    font-weight: 500;
}

.priceSuffix {
    color: var(--title-extra);
    font-size: 0.85em;
    margin-left: 2px;
}
