.button {
    --text-color: #201e26;
    --bg-color: #759acf;
    --bg-focus-color: #8bb6ef;

    position: relative;
    display: flex;
    align-items: stretch;
    height: 46px;
    background: none;
    color: var(--text-color);
    font-size: 0.9em;
    font-weight: bold;
    text-align: initial;
    border: 2px solid var(--bg-color);
    border-radius: 10px;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:not(.buttonDisabled, .buttonLoading):hover {
        border-color: var(--bg-focus-color);

        .buttonContent {
            background: var(--bg-focus-color);
        }
        .buttonTriangle {
            border-bottom-color: var(--bg-focus-color);
        }
        .buttonArrow {
            background: var(--bg-focus-color);
        }
    }
}

.buttonSecondary {
    --bg-color: #e7ad83;
    --bg-focus-color: #fbcbab;
}

.buttonOn {
    --bg-color: #7da376;
    --bg-focus-color: #9ae28d;
}

.buttonOff {
    --bg-color: #d67474;
    --bg-focus-color: #eb9d9d;
}

.buttonMute {
    --bg-color: #2b2b33;
    --bg-focus-color: #35353f;
    color: #95959d;
}

.buttonBase {
    --text-color: #636172;
    --bg-color: #303034;
    --bg-focus-color: #636172;

    .buttonContent {
        background: none;
    }

    &:not(.buttonDisabled, .buttonLoading):hover {
        .buttonContent {
            background: none;
        }
    }
}

.buttonColorfull {
    background: linear-gradient(60deg, #ef4e9e, #a166ab, #5073b8, #1098ad);
    color: #2f2f2f;
    animation: colorfullAnimatedgradient 3s ease-in-out alternate infinite;
    background-size: 200% 200%;
    box-shadow: 0 0 10px #759bcd7d;
    border: none;

    .buttonContent {
        background: none;
    }

    &:not(.buttonDisabled, .buttonLoading):hover {
        .buttonContent {
            background: none;
        }
    }
}

.buttonColorGold {
    background: linear-gradient(47deg, #e7dc3f, #cfa543, #ab7b0e);
    color: #2f2f2f;
    animation: goldAnimatedgradient 2.5s ease-in-out alternate infinite;
    background-size: 200% 200%;
    box-shadow: 0 0 10px rgb(204 205 117 / 50%);
    border: none;

    .buttonContent {
        background: none;
    }

    &:not(.buttonDisabled, .buttonLoading):hover {
        .buttonContent {
            background: none;
        }
    }
}

.buttonContentWrap {
    display: flex;
    align-items: stretch;
    width: 100%;
}

.buttonContentWrapHidden {
    visibility: hidden;
}

.buttonLoading {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #a8a8b7;
    border: 2px solid #a8a8b7;
    //padding: 0 25px;
    animation: none;
    box-shadow: none;
    cursor: default;
}

.buttonDisabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.buttonExtraInfo {
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.buttonTriangle {
    content: '';
    width: 0;
    height: 0;
    border-bottom: 56px solid var(--bg-color);
    border-left: 20px solid transparent;
    transition: all 0.2s ease-in-out;
}

.buttonContent {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bg-color);
    padding: 0 10px;
    transition: all 0.2s ease-in-out;
}
.buttonContentWithExtra {
    padding: 0 10px 0 5px;
}

.buttonArrow {
    position: relative;
    display: flex;
    align-items: center;
    width: 20px;
    background: var(--bg-color);
    transition: all 0.2s ease-in-out;

    .icon {
        position: absolute;
        left: -12px;
    }
}

.loadingWrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadingTextWrap {
    display: flex;
    margin-left: 10px;
}

.loadingTextDots {
    width: 1.3em;
    margin-left: 1px;

    &:after {
        content: '...';
        display: inline-block;
        vertical-align: bottom;
        width: 0px;
        letter-spacing: 1px;
        animation: ellipsis steps(4, end) 2s infinite;
        overflow: hidden;
    }
}

@keyframes ellipsis {
    to {
        width: 1.3em;
    }
}

@keyframes colorfullAnimatedgradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@keyframes goldAnimatedgradient {
    0% {
        background-position: 0% 50%;
        box-shadow: 0 0 10px rgb(204 205 117 / 70%);
    }
    50% {
        background-position: 100% 50%;
        box-shadow: 0 0 5px rgb(204 205 117 / 20%);
    }
    100% {
        background-position: 0% 50%;
        box-shadow: 0 0 10px rgb(204 205 117 / 70%);
    }
}