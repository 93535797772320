.networkTreeWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.treeMessage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1em;
}

.treeMessageBack {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #7791b3;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        color: #8daed7;
    }

    .icon {
        width: 25px;
        height: 25px;
        font-size: 25px;
        margin-right: 3px;
    }
}

.networkTree {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 420px;
    min-height: 200px;
    max-height: 420px;
    aspect-ratio: 10 / 9;
    transition: all 0.2s ease-in-out;
}

.networkTreeMini {
    min-height: 140px;
    aspect-ratio: unset;
}
  
.middleCircle {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 140px;
    background-color: #201e26;
    border: 2px solid #31323c;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 1;
}
  
.treeItem {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    background-color: #201e26;
    border-radius: 50%;
    border: 2px solid #31323c;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2);
    z-index: 1;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    opacity: 0.9;

    &:before {
        position: absolute;
        content: "";
        width: calc(50% - 45px);
        height: 1px;
        background-color: #5c6bc0;
        transform-origin: left;
    }

    &:hover {
        transform: scale(1.15);
        border-color: #5b6b9c;
        z-index: 2;
        opacity: 1;
    }

    &:hover + .line {
        background-color: #5b6b9c;
    }
}

.treeItemWrap {
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.treeItemWrapShow {
    opacity: 1;
}
  
.line {
    position: absolute;
    content: "";
    width: calc(50% - 90px);
    height: 2px;
    background: #31323c;
    transform-origin: left;
    transition: all 0.2s ease-in-out;
}
