/* Profile Activate */
.activateDescription {
    color: #8d8d99;
    font-size: 0.95em;
    font-weight: 500;
    line-height: 1.5em;
    margin-bottom: 1em;
    padding: 5px;
}

.taskWrap {
    border: 2px solid #313136;
    border-radius: 12px;
    transition: all 0.5s ease-in-out;
    overflow: hidden;

    &:not(:last-child) {
        margin-bottom: 0.7em;
    }
}

.taskWrapOpen {
    background: #201e26;
}

.taskTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
    font-size: 0.9em;
    font-weight: 500;
    padding: 15px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;

    .arrow {
        transition: all 0.5s ease-in-out;
    }
}

.taskTitleOpen {
    .arrow {
        transform: rotate(180deg);
    }
}

.taskContent {
    border-top: 2px solid #313136;
    overflow: hidden;
}

.activateTitleRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
}

.activateTitle {
    display: flex;
    align-items: center;
    gap: 7px;

    .number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        color: #292932;
        font-weight: bold;
        background: #b5b5b5;
        border-radius: 50%;
        padding-left: 1px;
    }
}

.activateState {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #696972;
    gap: 5px;
    font-weight: 500;
    background: #2e2e3b;
    border-radius: 20px;
    padding: 5px 7px;

    .state {
        font-size: 0.85em;
    }

    .icon {
        width: 14px;
        height: 14px;
    }
}

.activateStateDone {
    color: #56ab56;
}

.activateContentRow {
    padding: 15px;
}

.activateContentDescription {
    font-size: 0.85em;
    font-weight: 500;
    color: #696972;
}

/* Profile Connect */
.connectWrap {
    background: #2a2831;
    border-radius: 15px;
}

.connectAlert {
    margin-bottom: 1em;

    a {
        text-decoration: underline;
    }
}

.connectStatusWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 12px;
}

.connectStatusTitle {
    display: flex;
}

.connectStatusValue {
    display: flex;
    align-items: center;
    gap: 7px;

    .icon {
        width: 17px;
        height: 17px;
    }
}

.connectStatus {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
    font-size: 0.8em;
    margin-top: 4px;
    background: #201e26;
    border-radius: 50px;
    padding: 2px 5px;

    .icon {
        width: 14px;
        height: 14px;
    }
}

.connectStatusPending {
    color: #d17c7c;
}

.connectStatusConnected {
    color: #8dbd87;
}

.connectButton {
    min-width: 100px;
    height: 40px;
}

.connectInfo {
    border-top: 3px dashed #201e26;
    padding: 12px;

    &:not(:last-child) {
        margin-bottom: 15px;
    }
}

.connectInfoPair {
    display: flex;
    align-items: center;
    min-height: 25px;

    &:not(:last-child) {
        margin-bottom: 0.5em;
    }
}

/* Profile Mini */
.miniWrap {
    display: flex;
    align-items: center;
    gap: 10px;
}

.miniAvatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: #393a45;
    border-radius: 10px;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
    }

    .icon {
        width: 18px;
        height: 18px;
        color: #7f7f7f;
    }
}

.miniName {
    font-weight: 500;
}

/* Profile Overview */
.overviewWrap {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 1em;
}

.overviewAvatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    background: #201e26;
    border: 2px solid #2d2d34;
    border-radius: 50%;
    overflow: hidden;

    .image {
        width: 100%;
        height: 100%;
    }

    .icon {
        width: 35px;
        height: 35px;
        color: #3e3e43;
    }
}

.overviewLinkWrap {
    &:not(:last-child) {
        margin-bottom: 1em;
    }
}

.overviewDiscordHint {
    font-size: 0.75em;
    font-weight: 500;
    color: #696972;
    margin-left: 10px;
    margin-top: 7px;

    .icon {
        position: relative;
        top: 2px;
        margin-right: 5px;
    }

    .content {
        flex: 1;
    }
}

/* Profile Points */
.pointsWrap {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #bd973b;
    font-size: 0.95em;
    font-weight: bold;
    background: linear-gradient(45deg, #bd973b38, transparent);
    border: 2px solid #bd872d;
    box-shadow: 0 0 6px rgba(221, 175, 70, 0.25);
    text-shadow: 0 0 45px #bd872d;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    .num {
        min-width: 16px;
        text-align: center;
    }

    .icon {
        width: 18px;
        height: 18px;
    }

    &:hover {
        color: #eebc48;
        border-color: #e4a436;
    }
}

/* Profile Points Info */
.pointsInfoAlert {
    margin-bottom: 1em;
}

ul.pointsInfoBullets {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;

    li {
        display: flex;
        color: #8d8d99;
        font-size: 0.9em;
        font-weight: 500;
        padding: 4px;
        margin-left: 6px;

        &:not(:last-child) {
            margin-bottom: 1em;
        }

        .icon {
            margin-top: 3px;
            margin-right: 7px;
            flex: 0 0 16px;
            width: 16px;
            height: 16px;
        }
    }
}