.widgetWrap {
    display: flex;
    background: #201e26;
    border: 1px solid #2d2d34;
    border-radius: 12px;
    padding: 7px;
    overflow: hidden;
}

.widgetTabList {
    min-width: 28%;
    background: #2c2c35;
    margin-right: 7px;
    padding: 0px;
    border-radius: 8px;
    overflow: hidden;
}

.widgetTabItem {
    color: #696972;
    text-align: center;
    font-size: 0.95em;
    font-weight: 500;
    padding: 25px 15px;
    cursor: pointer;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out, border-top 0.2s ease-in-out, border-bottom 0.2s ease-in-out;

    &:not(:first-child) {
        border-top: 2px solid transparent;
    }

    &:not(:last-child) {
        border-bottom: 2px solid transparent;
    }

    &:hover:not(.widgetTabSelected) {
        background: #31313b;
    }
}

.widgetTabItemMobile {
    font-size: 0.9em;
}

.widgetTabSelected {
    color: #b5b5b5;
    background: #363641;
    cursor: default;

    &:not(:first-child) {
        border-top-color:#292631;
    }

    &:not(:last-child) {
        border-bottom-color: #292631;
    }
}

.widgetContent {
    flex: 1;
}