.wrap {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #8d8d99;
    font-size: 0.95em;
    font-weight: 500;
}

.wrapWithBackground {
    background: #2f2f38;
    border-radius: 15px;
    padding: 3px 9px 3px 7px;
    font-size: 0.75em;
    font-weight: auto;
    text-align: center;
}

.wrapSuccess {
    color: #7eb77e;
}
.wrapInfo {
    color: #75b8cd;
}
.wrapWarning {
    color: #b9b264;
}
.wrapError {
    color: #eb7287;
}
