.featureHint {
    display: flex;
    align-items: baseline;
    gap: 5px;
    font-size: 0.75em;
    font-weight: 500;
    color: #696972;
    margin-left: 10px;
    margin-top: 7px;

    .icon {
        position: relative;
        top: 3px;
        width: 14px;
        height: 14px;
    }
}

.cellMark {
    background: #141218;
    border-radius: 15px;
    padding: 4px 9px;
    font-size: 0.85em;
    font-weight: 500;
    white-space: nowrap;
}

.cellSuccess {
    color: #87b992;
}

.cellFail {
    color: #b98787;
}

.cellInfo {
    color: #8ba2bf;
}

/* Dashboard Mint */
.giftWrap {
    margin-bottom: 2.1em;
}

.mintWrap {
    margin-bottom: 2.1em;
}

.affiliateWrap {
    margin-bottom: 2.1em;
}

.pointsWrap {
    margin-bottom: 2.1em;
}

/* Dashboard Mint Gift */
.giftWrap {
    position: relative;
}
.giftIcon {
    position: absolute;
    top: -8px;
    left: 26px;
    width: 100px;
    height: 100px;
    transform: rotate(337deg);
    opacity: 0.7;
}

.giftTitleWrap {
    margin-left: 130px;
}

.giftTitle {
    font-size: 1.05em;
    font-weight: bold;
    margin-bottom: 5px;
}

.giftSubTitle {
    font-size: 0.85em;
    font-weight: 500;
    color: #696972;
}

/* Dashboard Mint Points */
.pointsAlert {
    margin-bottom: 1em;
}

.pointStatsWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.pointStats {
    display: flex;
    align-items: center;
    gap: 12px;

    .icon {
        width: 33px;
        height: 38px;
        border-right: 1px solid #37373e;
        padding-right: 10px;
    }
}

.pointStatsValue {
    display: flex;
    align-items: center;
    gap: 10px;

    
}

.pointStatsTool {
    color: #999999;
    background: #373741;
    border-radius: 5px;
    padding: 3px 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        color: #759acf;
    }

    .tool {
        display: block;
        width: 12px;
        height: 12px;
    }
}

.freePointsButton {
    display: flex;
    align-items: center;
    gap: 7px;

    .icon {
        width: 18px;
        height: 18px;
    }
}

/* Dashboard Mint Points History */
.sourceWrap {
    position: relative;
    display: flex;
    align-items: center;
    gap: 7px;

    .new {
        color: #b15565;
        font-size: 0.8em;
        font-weight: 500;
        border: 1px solid #b15565;
        border-radius: 10px;
        padding: 0px 3px;
    }
}

/* Dashboard Mint Rewards */
.rewardItem {
    display: flex;
    background: linear-gradient(51deg, rgba(168, 117, 205, 0.08), rgba(168, 117, 205, 0.05), transparent, transparent);
    border: 1px solid #675e7b;
    //box-shadow: 0 0 7px rgba(143, 128, 177, 0.3137254902);
    margin-bottom: 1em;
    border-radius: 10px;
}

.rewardText {
    display: flex;
    align-items: center;
    flex: 1 1;
    font-weight: 500;
    padding: 15px;
}

.rewardAmount {
    font-size: 0.9em;
    font-weight: 500;
}

.rewardButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2px;
    font-size: 0.9em;
    font-weight: 500;
    padding: 10px 20px;
    color: #8c83a1;
    border-left: 1px solid #675e7b;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: rgb(168 117 205 / 8%);
    }
}

.rewardItemGreen {
    background: linear-gradient(51deg, rgb(118 205 117 / 8%), rgb(117 205 141 / 5%), transparent, transparent);
    border: 1px solid #566554;
}

.rewardButtonGreen {
    color: #85a183;
    border-left: 1px solid #566554;

    &:hover {
        background: rgb(118 205 117 / 8%);
    }
}

.statList {
    display: flex;
    margin-bottom: 10px;
}

.statItem {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    &:not(:last-child) {
        margin-right: 10px;
    }

    .label {
        font-size: 0.75em;
        font-weight: 500;
        color: #696972;
    }
    .content {
        display: flex;
        align-items: center;
        gap: 5px;
        font-weight: 500;
        margin-bottom: 3px;
    }
    .subContent {
        font-size: 0.85em;
        font-weight: normal;
    }
}

.commissionContent {
    display: flex;
    align-items: center;
}

.commissionValue {
    display: flex;
    align-items: center;
}

.commissionButton {
    display: flex;
    align-items: center;
    background: #343444;
    font-size: 0.85em;
    font-weight: 500;
    padding: 4px 6px;
    border-radius: 6px;
    cursor: pointer;

    .icon {
        margin-left: 4px;
    }
}

/* Dashboard Mint Rewards Rules */
.alert {
    margin-bottom: 20px;
}

.cellActive {
    background: #2d2d38;
}

.ruleheader {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ruleHeaderTitle {
    color: #b5b5b5;
}

.ruleHeaderCurrent {
    color: #7d7d85;
    font-size: 0.85em;
    font-weight: 500;
    padding: 3px 12px !important;
    margin-top: 5px;
}

/* Dashboard Mint Affiliate */
.inviteFeature {
    display: flex;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 0.5em;
    }
}

ul.inviteBullets {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;

    li {
        display: flex;
        align-items: center;
        font-size: 0.95em;
        font-weight: 500;
        padding: 4px;
        margin-left: 6px;

        .icon {
            margin-right: 7px;
        }
    }
}

.inviteLink {
    flex: 1;
    min-width: 0;
}

.inviteLinkLabel {
    font-size: 0.85em;
    font-weight: 500;
    color: #696972;
    margin-bottom: 4px;
}

.inviteLinkAddress {
    width: 100%;
    background: none;
    color: #cbcbcb;
    font-weight: bold;
    border: none;
    border-bottom: 1px dashed #9d9d9d;
    font-size: 0.95em;
    outline: none;
}

.inviteCopyButton {
    width: 100px;
    height: 46px;
    margin-left: 20px;
}

.inviteCirclesLink {
    color: #7f99c7;
    cursor: pointer;
    text-decoration: underline;
}