.inputWrap {
    display: flex;
    min-width: 140px;
    background: #292932;
    border: 2px solid #303039;
    border-radius: 14px;
    overflow: hidden;
}

.input {
    width: 0;
    flex: 1;
    font-weight: 500;
    padding: 10px;
    text-align: center;
    color: #b5b5b5;
    background: none;
    border: none;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:focus {
        outline: none;
    }
}

.wheel {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:not(.wheelDisabled):hover {
        background: #303039;

        &::after {
            opacity: 0;
        }
    }
}

.wheelDisabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.wheelMinus {
    &::after {
        content: '';
        position: absolute;
        top: 10px;
        right: 0;
        width: 1px;
        height: calc(100% - 20px);
        background: #3b3b47;
        transition: all 0.2s ease-in-out;
    }
}

.wheelPlus {
    &::after {
        content: '';
        position: absolute;
        top: 10px;
        left: 1px;
        width: 1px;
        height: calc(100% - 20px);
        background: #3b3b47;
        transition: all 0.2s ease-in-out;
    }
}