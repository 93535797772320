/* Plat Content */
.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #37373e;
    padding-top: 10px;
    margin-top: 20px;

    .total {
        background: #31323c;
        font-size: 0.85em;
        font-weight: 500;
        border-radius: 10px;
        padding: 10px 15px;
        margin-right: 20px;
    }

    .viewAll {
        display: flex;
        align-items: center;
        font-size: 0.85em;
        font-weight: 500;
        color: #afafaf;
        background: #31323d;
        padding: 10px 30px;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            background: #201e26;
            color: #769ac5;
        }

        .icon {
            width: 15px;
            height: 15px;
            margin-left: 8px;
        }
    }
}

.footerPagination {
    justify-content: center;

    button {
        color: var(--primary-color);
        font-weight: 500;
    }
}

/* Plat Header */
.header {
    display: flex;
    align-items: center;
    text-align: start;
}

.titleGroup {
    flex: 1;

    .title {

    }

    .total {
        color: var(--title-sub);
        font-size: 0.8em;
        margin-top: 3px;
    }
}

.searchField {
    width: 50%;
    max-width: 135px;
    transition: all 0.3s ease-in-out;

    &:focus-within {
        max-width: 500px;
    }

    *:disabled {
        cursor: not-allowed;
    }
}

.searchIcon {
    color: var(--title-sub);
    margin-left: 15px;
}

.clearIcon {
    width: 22px;
    height: 22px;
    padding: 3px;
    border-radius: 50%;
    margin-right: 15px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: var(--line-color);
    }
}

.searchFieldExpand {
    max-width: 500px;
}