html,
body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

#__next {
    width: 100%;
    height: 100%;
}

#root {
    width: 100%;
    height: 100%;
}

body {
    background: #201e26;
    color: #b5b5b5;
}

a {
    color: #869fbf;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}

a:hover {
    color: #96b2d7;
    text-decoration: underline;
}

* {
    box-sizing: border-box;
}

.tooltip {
    max-width: 200px;
    font-weight: 500;
    line-height: 1.5em;
    padding: 1em !important;
    border-radius: 10px !important;
}

.alertAction {
    background: #201e26;
    color: #b5b5b5;
    padding: 8px 10px;
    border-radius: 2px;
    cursor: pointer;
    font-size: 0.9em;
    font-weight: bold;
}
