.stateWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;

    .icon {
        height: 85px;
        margin-bottom: 20px;
    }

    .message {
        background: #2d2e3b;
        color: #737d89;
        font-size: 0.95em;
        font-weight: bold;
        border-radius: 10px;
        padding: 10px 15px;
    }
}
