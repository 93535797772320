/* Wallet Connect */
.tools {
    display: flex;
    align-items: center;
}

.tool {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 17px;
    border-radius: 10px;
    margin-left: 10px;
}

.balance {
    color: #696972;
    font-size: 0.9em;
    font-weight: 500;
    text-align: right;
    padding: 2px 2px;

    &:not(:last-child) {
        border-bottom: 1px solid #373741;
    }
}

.toolBox {
    color: #cbcbcb;
    background: #32323e;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: #393946;
    }
}

/* Wallet Connect Text */
.idleText {
    display: inline-block;
    height: auto;
    line-height: initial;
    background: none;
    color: #87a1c1;
    font-family: inherit;
    font-size: 0.9em;
    font-weight: 500;
    border-radius: initial;
    border-bottom: 1px dashed #87a1c1;
    padding: 0;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-image: none !important;
        color: #ccdcef;
    }
}
