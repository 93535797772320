.field {
    display: flex;
    align-items: center;
    background: #2b2c39;
    border-radius: 5px;
    outline: 2px solid transparent;
    transition: all 0.2s ease-in-out;
}
.field:not(.fieldError):focus-within {
    outline: 2px solid #435e9d;
}

.field input,
.field textarea {
    flex: 1;
    width: 100%;
    min-width: 0;
    background: none;
    border: none;
    font-size: 0.9em;
    color: #d9d9d9;
    padding: 18px 15px;

    &:focus {
        outline: none;
    }

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #666;
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #666;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #666;
    }
}

.fieldError {
    outline: 2px solid #722828;
}

.label {
    display: flex;
    font-size: 0.9em;
    font-weight: 500;
    margin-bottom: 7px;
}

.labelStart {
    flex: 1;
}

.labelEnd {
    margin-left: 10px;
}

.error {
    color: #a53f3f;
    font-size: 0.85em;
    font-weight: 500;
    margin-top: 2px;
}
