.progressWrap {
    margin-bottom: 5px;
}

.statList {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.statItem {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;

    &:not(:last-child) {
        border-right: 1px solid #424248;
        margin-right: 20px;
        padding-right: 20px;
    }

    .label {
        font-size: 0.9em;
        font-weight: 500;
        color: #696972;
    }
    .content {
        font-weight: 500;
        margin-left: 10px;
        text-align: right;
    }
}

.confettiWrap {
    display: flex;
    justify-content: center;
}

.mintAlert {
    margin-bottom: 2em;
}

.mintAlertHint {
    margin-left: 7px;
}

.mintAlertContent {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
        font-weight: 500;
    }

    .subtitle {
        font-size: 0.9em;
        font-weight: 500;
        color: #696972;
        margin-top: 2px;
    }
}

.mintButtonWrap {
    display: flex;
    gap: 10px;

    > * {
        flex: 1;
    }
}

.mintCount {
    flex: 0 0 135px;
}

.mintButton {
    &:disabled {
        opacity: 0.6;
    }
}
