.nav {
    display: flex;
    align-items: center;
}

.navMobile {
    padding: 15px;
}

.logo {
    width: 60px;
    margin-right: 10px;
}

.titleWrap {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
}

.title {
    font-size: 2em;
    margin: 0;
    margin-right: 7px;
}

.titleMobile {
    font-size: 1.3em;
}

.state {
    position: relative;
    bottom: 3px;
    color: #201e26;
    font-size: 0.8em;
    font-weight: bold;
    border-radius: 10px;
    padding: 2px 3px;
    background: #b5b5b5;
}

.subTitle {
    color: #4f4f54;
    font-size: 1em;
    letter-spacing: 0.15em;
}

.toolsWrap {
    margin-left: auto;
}
