.tabWrap {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.tabScrollContent {
    flex: 1;
}

.labelList {
    display: flex;
    border: 2px solid #2b2c39;
    border-radius: 30px;
    padding: 4px;
    margin-bottom: 2.1em;
}

.labelListFlat {
    border-radius: 0;
}

.labelListLine {
    border-radius: 0;
    border-left: none;
    border-right: none;
    padding: 4px 6px;
}

.labelItem {
    flex: 1 1;
    text-align: center;
    font-size: 0.95em;
    font-weight: bold;
    color: #807e89;
    padding: 13px 10px;
    border-radius: 30px;
}

.labelItem:not(.labelActive) {
    cursor: pointer;
}

.labelActive {
    color: #121214;
    background: #758ccb;
    cursor: default;
    transition: all 0.2s ease-in-out;
}
