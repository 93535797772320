.wrap {
    display: flex;
    align-items: center;
    background: #202028;
    color: #7d7d85;
    border: 1px solid #2d2d34;
    border-radius: 10px;
    padding: 15px;
    overflow: hidden;

    a {
        color: inherit;
        text-decoration: underline;

        &:hover {
            color: inherit;
        }
    }
}
.wrapSuccess {
    border: 1px solid #71bb80;
    color: #71bb80;
}
.wrapInfo {
    border: 1px solid #4b99a7;
    color: #4b99a7;
}
.wrapWarning {
    border: 1px solid #b9b264;
    color: #b9b264;
}
.wrapError {
    border: 1px solid #cd4a61;
    color: #cd4a61;
}

.icon {
    width: 25px;
    height: 25px;
    margin-right: 15px;
}
.iconSuccess {
    color: #5dd875;
    filter: drop-shadow(0 0 20px #5dd875);
}
.iconInfo {
    color: #5cc3d7;
    filter: drop-shadow(0 0 20px #5cc3d7);
}
.iconWarning {
    color: #e4d849;
    filter: drop-shadow(0 0 20px #e4d849);
}
.iconError {
    color: #e44965;
    filter: drop-shadow(0 0 20px #e44965);
}

.message {
    width: 100%;
    font-size: 0.9em;
    line-height: 1.3em;
    font-weight: 500;
}

.clear {
    opacity: 0.7;
    width: 27px;
    height: 27px;
    padding: 2px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        opacity: 1;
        transform: scale(1.1);
    }
}
