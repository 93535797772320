.container {
    display: flex;
    width: 100%;
    height: 100%;
}

.containerMobile {
    font-size: 14px;
}

.menu {
    border-right: 2px solid #2b2a38;
}

.main {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 1330px;
    padding: 70px;
    margin: 0 auto;
}
.mainMobile {
    padding: 0;
}

.nav {
    margin-bottom: 2.1em;
}

.navMobile {
    margin-top: 1em;
    margin-bottom: 1em;
}

.contentWrap {
    flex: 1;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.contentWrapMobile {
    display: block;
}

.dashboard {
    flex: 1;
    min-width: 0;
    max-width: 560px;
    margin-bottom: 5em;
}

.preview {
    flex: 1;
    min-width: 400px;
    max-width: 520px;
    /*position: sticky;*/
    top: 2.1em;
    align-self: flex-start;
    margin-right: 2.1em;
    margin-bottom: 5em;
}

.previewMobile {
    width: 100%;
    min-width: auto;
    max-width: auto;
    margin-bottom: 1em;
    padding-top: 1em;
    border-top: 2px solid #2b2c39;
}

.previewInfoMobile {
    justify-content: space-around;
    background: #272731;
    padding: 15px;
    margin-bottom: 2.1em;
}
