/* Dashboard Message */
.wrap {
    --borderWidth: 1px;
    position: relative;
    display: flex;
    align-items: center;
    background: #18181a;
    border-radius: 17px;
    padding: 15px 20px;
    margin-bottom: 2.1em;

    &:after {
        content: '';
        position: absolute;
        top: calc(-1 * var(--borderWidth));
        left: calc(-1 * var(--borderWidth));
        height: calc(100% + var(--borderWidth) * 2);
        width: calc(100% + var(--borderWidth) * 2);
        background: linear-gradient(190deg, #8b5454, transparent, #403131);
        border-radius: 18px;
        z-index: -1;
    }
}

.wrapMobile {
    margin: 0 1.5em 2em 1.5em;
}

.content {
    flex: 1;
}

.close {
    color: #7c7c7c;
    background: #2d2e38;
    border-radius: 50%;
    padding: 7px;
    margin-left: 1.5em;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        color: #b5b5b5;
        background: #2b2c39;
    }

    .icon {
        display: block;
        width: 21px;
        height: 21px;
    }
}

.messageWrap {
    display: flex;
    align-items: center;
}

.messageWrapMobile {
    flex-direction: column;
    align-items: baseline;
}

.messageIconWrap {
    margin-right: 1em;

    & > * {
        display: block;
        width: 28px;
        height: 28px;
    }
}

.messageTitleWrap {
    flex: 1;
}

@keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}