.cellTop {
    font-weight: 500;
    margin-bottom: 3px;
}

.cellMark {
    background: #141218;
    border-radius: 15px;
    padding: 4px 9px;
    font-size: 0.85em;
    font-weight: 500;
    white-space: nowrap;
}

.cellSuccess {
    color: #87b992;
}

.cellFail {
    color: #b98787;
}

.cellInfo {
    color: #8ba2bf;
}

/* Wallet Claims */
.sectionTitle {
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: 0.95em;
    font-weight: 500;
    margin-left: 6px;
    margin-bottom: 8px;

    .icon {
        width: 24px;
        height: 24px;
    }
}

.walletOverview {
    margin-bottom: 1.5em;
}

.sectionTabs {
    margin-top: 2em;
}

.sectionTabsLabelWrap {
    margin-bottom: 1em;
}

/* Wallet Overview */
.overviewWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.assets {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-right: 15px;
}

.assetItem {
    flex: 1;
}

.assetItem:not(:first-child) {
    border-left: 1px solid #37373e;
    padding-left: 15px;
}

.assetLabel {
    font-size: 0.85em;
    font-weight: 500;
    color: #696972;
    margin-top: 3px;
}

.assetAmount {
    font-weight: 500;
}

.actionButton {
    width: 100px;
    height: 46px;
    margin-left: 20px;
}