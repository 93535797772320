.previewWrap {
    //background: linear-gradient(137deg, #24222a, transparent, #24222a);
    //border-top: 2px solid #2b2c38;
    background: linear-gradient(37deg, #24222a, transparent, #24222a);
    border-left: 2px solid #2b2c38;
    border-radius: 10px;
    padding: 20px 25px;
    font-size: 1.1em;
}

.previewSection {
    border-bottom: 2px dashed #2f2d36;
    padding-bottom: 10px;
    margin-bottom: 1em;
}

.previewSectionTitle {
    display: table;
    font-weight: bold;
    background: linear-gradient(40deg, #a283b9, #8299bb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.previewSectionSub {
    font-size: 0.9em;
    font-weight: 500;
    background: #29272f;
    border-radius: 12px;
}

.logoWrap {
    background: linear-gradient(28deg, #2a2a36, #212028, #2a2a36);
    border-radius: 12px;
    padding: 9px;
    margin-right: 20px;
}

.logo {
    width: 55px;
    height: 55px;
}

/* Preview */
.aboutWrap {
    margin-bottom: 2.1em;
}

/* Preview Image */
.previewImage {
    margin-bottom: 2.1em;
}

.imageWrap {
    img {
        width: 100%;
        min-height: 360px;
        object-fit: cover;
        background: #201e26;
        border-radius: 15px;
    }
}

/* Preview Info */
.info {
    display: flex;
    margin-bottom: 2.1em;
}

.infoMobile {
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 1em;
}

.infoTitleWrap {
    flex: 1;

    .title {
        font-size: 1.35em;
        font-weight: bold;
        margin-bottom: 4px;
    }
    
    .titleMobile {
        font-size: 1.05em;
    }
    
    .description {
        color: #616168;
        font-size: 0.85em;
        font-weight: 500;
        margin-bottom: 0.5em;
    }
}

.socialList {
    display: flex;
}

.socialItem {
    color: #616168;
    transition: all 0.2s ease-in-out;

    &:not(:last-child) {
        margin-right: 20px;
    }

    &:hover {
        color: #769ac5;
    }
}

.total {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    margin-left: auto;
    border-left: 1px solid #37363a;

    .content {
        font-size: 1.55em;
        font-weight: bold;
        margin-bottom: 4px;
    }
    .label {
        font-size: 0.85em;
        color: #696972;
    }
}

.learnMore {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.95em;
    font-weight: 500;
    background: linear-gradient(1deg, #24232d, #282733);
    border-top: 2px solid #2d2b37;
    box-shadow: 0 0 4px rgb(0 0 0 / 40%);
    border-radius: 15px;
    padding: 15px;
    margin-left: 10px;
    grid-gap: 5px;
    grid-gap: 5px;
    gap: 5px;

    .arrow {
        transition: all 0.5s ease-in-out;
    }
}

.learnMoreOpen {
    .arrow {
        transform: rotate(180deg);
    }
}

/* Preview Summary */
.summaryContent {
    flex: 1;

    .title {
        font-size: 1.1em;
        font-weight: 500;
        margin-bottom: 5px;
    }
    
    .description {
        color: #7f7f83;
        font-size: 0.9em;
        font-weight: 500;

        .descriptionItem {
            &:not(:last-child) {
                margin-bottom: 0.7em;
            }
        }

        a {
            display: inline-flex;
            align-items: center;
            gap: 3px;
            color: #869fbf;
            transition: all 0.2s ease-in-out;
            cursor: pointer;

            &:hover {
                color: #96b2d7;
                text-decoration: underline;
            }
        }
    }
}

.summaryWrap {
    --borderWidth: 2px;
    position: relative;
    display: flex;
    flex-direction: column;
    background: #201e26;
    border-radius: 17px;
    padding: 20px;
    margin-bottom: 2.1em;
    gap: 1em;

    &:after {
        content: "";
        position: absolute;
        top: calc(-1 * var(--borderWidth));
        left: calc(-1 * var(--borderWidth));
        height: calc(100% + var(--borderWidth) * 2);
        width: calc(100% + var(--borderWidth) * 2);
        background: linear-gradient(
            60deg,
            #f79533,
            #f37055,
            #ef4e7b,
            #a166ab,
            #5073b8,
            #1098ad,
            #07b39b,
            #6fba82
        );
        border-radius: 18px;
        z-index: -1;
        animation: animatedgradient 9s ease alternate infinite;
        background-size: 300% 300%;
    }
}

.summaryWrapMobile {
    --borderWidth: 2px;
    margin-left: 15px;
    margin-right: 15px;
}

.summaryButton {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7c7c7c;
    text-align: center;
    font-size: 0.95em;
    font-weight: 500;
    background: linear-gradient(1deg, #272631, #2a2933);
    border-top: 2px solid #32303f;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    padding: 15px;
    cursor: pointer;
    gap: 10px;
    transition: all 0.2s ease-in-out;

    &:hover {
        opacity: 0.8;
        color: #b5b5b5;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
    }

    .icon {
        display: block;
        width: 21px;
        height: 21px;
    }
}

@keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* Preview About */
.about {
    font-size: 0.85em;
    font-weight: 500;
    color: #696972;
    margin-bottom: 1em;
}

.groupWrap {
    background: #29272f;
    border-radius: 12px;
    transition: all 0.5s ease-in-out;
    overflow: hidden;

    &:not(:last-child) {
        margin-bottom: 0.5em;
    }
}

.groupWrapOpen {
    background: #2d2b34;
}

.groupTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9em;
    font-weight: 500;
    padding: 12px 15px;
    cursor: pointer;

    .arrow {
        transition: all 0.5s ease-in-out;
    }
}

.groupTitleOpen {
    .arrow {
        transform: rotate(180deg);
    }
}

.groupContent {
    border-top: 2px solid #201e26;
    overflow: hidden;
}

/* Preview About Faq */
.about, .faqList {
    a {
        color: #869fbf;
        transition: all 0.2s ease-in-out;
        cursor: pointer;

        &:hover {
            color: #96b2d7;
            text-decoration: underline;
        }
    }
}

/* Preview About Grades */
.gradesWrap {
    margin-bottom: 0.5em;
}

.gradeTitle {
    display: flex;
    align-items: center;
    padding: 12px 15px;
    border-bottom: 2px solid #201e26;
    gap: 10px;

    .extra {
        color: #9e83b3;
        font-size: 0.75em;
        background: #201e26;
        border-radius: 15px;
        padding: 4px 10px;
    }
}

.gradeDescription {
    color: #8d8d99;
    font-size: 0.85em;
    font-weight: 500;
    padding: 12px 15px 0 15px;
}

.gradeList {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px 15px;
}

.gradeItem {
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    color: rgb(0 0 0 / 45%);
    font-size: 0.85em;
    font-weight: bold;
    margin: 0;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: translateY(-3px);
        color: rgb(0 0 0 / 60%);
    }

    &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background-color: #ffffff3d;
        border-radius: -23px;
        clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
        z-index: 1;
    }
}

.gradeItemBronze {
    background: radial-gradient(#ffc6ad, #b96e4d);
    border: 2px solid #cb8e74;
    box-shadow: 0 0 6px 0 rgb(199, 164, 147, 0.5);

    &:hover {
        box-shadow: 0 6px 18px -4px rgb(199, 164, 147, 0.4);
    }
}

.gradeItemSilver {
    background: radial-gradient(#ffffff, #b3b3b3);
    border: 2px solid #d1d1d1;
    box-shadow: 0 0 6px 0 rgba(189, 191, 190, 0.5);

    &:hover {
        box-shadow: 0 6px 18px -4px rgba(189, 191, 190, 0.4);
    }
}

.gradeItemGold {
    background: radial-gradient(#ffebc8, #bf9342);
    border: 2px solid #d3ab6c;
    box-shadow: 0 0 6px 0 rgba(199, 181, 147, 0.5);

    &:hover {
        box-shadow: 0 6px 18px -4px rgba(199, 181, 147, 0.4);
    }
}

.gradeItemPlatinum {
    background: radial-gradient(#d3e5f3, #919ca3);
    border: 2px solid #a6b5bf;
    box-shadow: 0 0 6px 0 rgba(225, 225, 225, 0.5);

    &:hover {
        box-shadow: 0 6px 18px -4px rgba(225, 225, 225, 0.4);
    }
}

/* Preview Projects */
.projectWrap {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 0.9em;
    font-weight: 500;

    &:not(:last-child) {
        margin-bottom: 1em;
    }
}

.projectNum {
    flex: 0 0 4em;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    background: #29272f;
    border-radius: 12px;
}

.projectDetails {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 0.9em;
    font-weight: 500;
    background: #29272f;
    border-radius: 12px;
    padding: 15px 20px;
}

.projectTitle {
    flex: 1;

    .title {
        font-size: 1.1em;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .subTitle {
        color: #7f7f83;
        font-size: 0.9em;
        font-weight: 500;
    }
}

.projectButton {
    flex: 0 0 8em;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    color: #7c7c7c;
    font-size: 0.95em;
    font-weight: 500;
    background: #222028;
    border-bottom: 2px solid #2f2c37;
    border-radius: 12px;
    padding: 12px;
    margin-left: 1em;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: #1d1c23;
        color: #b5b5b5;
    }
}