.faqItem {
    transition: all 0.2s ease-in-out;

    &:not(:last-child) {
        border-bottom: 2px solid #201e26;
    }
}

.faqItemActive {
    background: #34323c;
}

.faqTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #8d8d99;
    font-size: 0.85em;
    font-weight: 500;
    padding: 12px 15px;
    cursor: pointer;

    .icon {
        width: 14px;
        height: 14px;
        font-size: 14px;
    }
}

.faqContent {
    padding: 12px 15px;
    overflow: hidden;
    color: #696972;
    font-size: 0.8em;
    font-weight: 500;
    border-top: 2px dashed #201e26;
}