.pairTopWrap {
    display: flex;
    flex-direction: column;

    .pairTitle {
        margin-bottom: 2px;
    }
}

.pairWrapBottom {
    display: flex;
    flex-direction: column-reverse;

    .pairTitle {
        margin-top: 2px;
    }
}

.pairWrapStart {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.pairWrapEnd {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}

.pairTitle {
    color: #616168;
    font-size: 0.85em;
    font-weight: 500;
}

.pairValue {
    color: #b5b5b5;
    font-weight: 500;
}
