.boxFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .total {
        background: #31323c;
        font-size: 0.85em;
        font-weight: 500;
        border-radius: 10px;
        padding: 10px 15px;
        margin-right: 20px;
    }

    .viewAll {
        display: flex;
        align-items: center;
        font-size: 0.85em;
        font-weight: 500;
        color: #afafaf;
        background: #31323d;
        padding: 10px 30px;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            background: #201e26;
            color: #769ac5;
        }

        .icon {
            width: 15px;
            height: 15px;
            margin-left: 8px;
        }
    }
}

.pagination {
    display: flex;
    justify-content: center;
}

.cellSub {
    color: #636172;
    font-size: 0.8em;
    font-weight: 500;
    margin-top: 3px;
}

.cellExtra {
    color: #636172;
    font-size: 0.75em;
    font-weight: 500;
    margin-left: 5px;
}

.cellMark {
    background: #141218;
    border-radius: 15px;
    padding: 4px 9px;
    font-size: 0.85em;
    font-weight: 500;
    white-space: nowrap;
}

.cellSuccess {
    color: #87b992;
}

.cellFail {
    color: #b98787;
}

.cellInfo {
    color: #8ba2bf;
}

/* Dashboard Invites */
.sectionLabel {
    display: flex;
    align-items: center;
    color: #afafaf;
    margin-bottom: 10px;

    .icon {
        width: 40px;
        height: 40px;
        margin-right: 15px;
        margin-left: 6px;
    }

    .title {
        font-size: 1.05em;
        font-weight: 500;
        margin-bottom: 2px;
    }

    .titleSub {
        color: #616168;
        font-size: 0.85em;
        font-weight: 500;
    }
}

.summarySection {
    margin-bottom: 2.1em;
}

.mintsSection {
    margin-bottom: 2.1em;
}

.rewardsSection {
    margin-bottom: 2.1em;
}

/* Dashboard Invite leaders */
.leaderOverview {
    margin-bottom: 1em;
}

.leaderMyRank {
    display: flex;
    align-items: center;
    gap: 7px;
}

.leaderOverviewHint {
    font-size: 0.75em;
    font-weight: 500;
    color: #696972;
    margin-left: 10px;
    margin-top: 7px;

    .icon {
        position: relative;
        top: 2px;
        margin-right: 5px;
    }

    .content {
        flex: 1;
    }
}

.leaderGuideHint {
    font-size: 0.9em;
    font-weight: 500;
    color: #696972;
    margin-left: 10px;
    margin-top: 15px;

    .icon {
        position: relative;
        top: 2px;
        margin-right: 5px;
    }

    .content {
        flex: 1;
    }
}

.leaderOverviewContent {
    display: flex;
    gap: 10px;
}

.leaderOverviewItem {
    flex: 1;
}

.leaderRank {
    display: inline-block;
    font-weight: bold;
    font-size: 0.9em;
    background: #141218;
    border-radius: 20px;
    padding: 3px 10px;
}

.leaderRankOne {
    background: #c9c47b;
    color: #333333;
    box-shadow: 0 0 5px #c9c47b;
}

.leaderRankTwo {
    background: #b9c6c7;
    color: #333333;
    box-shadow: 0 0 5px #b9c6c7;
}

.leaderRankThree {
    background: #d59182;
    color: #333333;
    box-shadow: 0 0 5px #d59182;
}

/* Dashboard Invite Network */
.treeCenterWrap {
    text-align: center;

    .title {
        font-size: 0.95em;
        font-weight: 500;
        margin-bottom: 7px;
    }

    .sub {
        color: #616168;
        font-size: 0.8em;
        font-weight: 500;
    }
}

.treeItemWrap {
    text-align: center;

    .title {
        font-size: 0.8em;
        font-weight: 500;
        margin-bottom: 3px;
    }

    .sub {
        color: #616168;
        font-size: 0.8em;
        font-weight: 500;
    }
}

.treeMessageTitle {
    display: flex;
    align-items: center;

    .title {
        font-size: 0.95em;
        font-weight: 500;
    }

    .sub {
        color: #616168;
        font-size: 0.8em;
        font-weight: 500;
        margin-left: 7px;
    }
}

/* Dashboard Invites Summary */
.invitesSummaryRow {
    display: flex;
    gap: 10px;

    &:not(:last-child) {
        margin-bottoM: 10px;
    }
}

.invitesSummaryItem {
    flex: 1;
}

.circlesGuideButton {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7c7c7c;
    font-size: 0.95em;
    font-weight: 500;
    text-align: center;
    background: linear-gradient(1deg, #272631, #2a2933);
    border-top: 2px solid #32303f;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    padding: 15px;
    border-radius: 15px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        opacity: 0.8;
        color: #b5b5b5;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
    }
}
