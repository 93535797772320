/* Dashboard Nfts */
.nftsSummaryWrap {
    margin-bottom: 2.1em;
}

.pagination {
    display: flex;
    justify-content: center;
    background: #282832;
    padding: 10px;
    border-radius: 48px;
    margin-top: 2.1em;
}

/* Dashboard Nfts Item */
.nftItem {
    padding: 10px !important;
}

.imageWrap {
    position: relative;
    border-radius: 12px;
    margin-bottom: 0.5em;
    overflow: hidden;
}

.imageWrapBronze {
    --grade-color: #df9a7f;
    border: 4px solid var(--grade-color);
    box-shadow: 0 0 6px rgba(199, 164, 147, 0.5);
}

.imageWrapSilver {
    --grade-color: #d1d1d1;
    border: 4px solid var(--grade-color);
    box-shadow: 0 0 6px rgba(189, 191, 190, 0.5);
}

.imageWrapGold {
    --grade-color: #debc87;
    border: 4px solid var(--grade-color);
    box-shadow: 0 0 6px rgba(199, 181, 147, 0.5);
}

.imageWrapPlatinum {
    --grade-color: #cad8e3;
    border: 4px solid var(--grade-color);
    box-shadow: 0 0 6px rgba(225, 225, 225, 0.5);
}

.image {
    display: block;
    width: 100%;
    height: 190px;
    background: #201e26;
    object-fit: cover;
    text-indent: 100vw; // to remove border for empty src
}

.imageMobile {
    height: 150px;
}

.overlayShadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 25px 0 #00000091;

    &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.13);
        border-radius: -23px;
        clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
        z-index: 1;
    }
}

.overlayLink {
    opacity: 0;
    transform: scale(0.7);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-in-out;
}

.overlayLinkShow {
    opacity: 1;
    transform: scale(1.01);
}

.overlayGradeWrap {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
}

.overlayGrade {
    --border-size: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    min-width: 75px;
    color: rgba(0, 0, 0, 0.55);
    font-size: 0.85em;
    font-weight: bold;
    background: var(--grade-color);
    border-bottom: none;
    padding: 6px 20px 3px 20px;
    border-radius: 20px 20px 0 0;
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 78%);

    span {
        z-index: 1;
    }

    &:after,
    &:before {
        content: "";
		position: absolute;
		height: var(--border-size);
		width: calc(var(--border-size) * 2);
		bottom: 0;
        z-index: 0;
    }
    
    &:before {
        left: calc(var(--border-size) * -2);
		border-radius: 0 0 var(--border-size) 0;
		-moz-border-radius: 0 0 var(--border-size) 0;
		-webkit-border-radius: 0 0 var(--border-size) 0;
		-webkit-box-shadow: var(--border-size) 0 0 0 var(--grade-color);
		box-shadow: var(--border-size) 0 0 0 var(--grade-color);
    }
    
    &:after {
        right: calc(var(--border-size) * -2);
		border-radius: 0 0 0 var(--border-size);
		-moz-border-radius: 0 0 0 var(--border-size);
		-webkit-border-radius: 0 0 0 var(--border-size);
		-webkit-box-shadow: calc(-1 * var(--border-size)) 0 0 0 var(--grade-color);
		box-shadow: calc(-1 * var(--border-size)) 0 0 0 var(--grade-color);
    }
}

.viewSolscan {
    display: flex;
    align-items: center;
    background: #000000c2;
    color: #afafaf;
    font-size: 0.85em;
    font-weight: 500;
    padding: 7px 10px;
    border-radius: 25px;

    .icon {
        width: 14px;
        height: 14px;
        margin-left: 4px;
    }
}

.titleWrap {
    padding: 5px;
    margin-bottom: 0.5em;
}

.topTitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
}

.title {
    font-weight: 500;
}

.rarity {
    background: #201e26;
    font-size: 0.85em;
    color: #787878;
    font-weight: 500;
    padding: 4px 6px;
    border-radius: 6px;
    margin-left: 6px;
}

.subTitle {
    font-size: 0.85em;
    font-weight: 500;
    color: #696972;
}

.nftGradeWrap {
    display: flex;
    align-items: center;
    padding: 8px 8px 8px 12px;
}

.nftUpgradeStatus {
    flex: 1;
}

.nftUpgradeStatusLabel {
    font-size: 0.95em;
}

.nftUpgradeStatusValue {
    font-size: 0.9em;
}

.nftUpgradeButton {
    font-size: 0.8em;
    margin-left: 3px;
}

/* Dashboard Nfts Item Upgrade */
.upgradeAlert {
    margin-bottom: 1em;
}

.upgradeDescription {
    color: #8d8d99;
    font-size: 0.95em;
    font-weight: 500;
    line-height: 1.5em;
    margin-bottom: 1em;
}

.upgradeTo {
    margin-bottom: 1em;
}

.upgradeButtons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.doUpgradeButton {
    width: 100px;
}

/* Dashboard Nfts Loading */
.loadingImage {
    display: block;
    margin-bottom: 13px;
}

.loadingTitle {
    display: block;
    margin-bottom: 2px;
}

/* Dashboard Nfts Summary */
.gradeEligibleAlert {
    margin-bottom: 1em;
}

.nftsSummary {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.gradesGuideButton {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7c7c7c;
    font-size: 0.95em;
    font-weight: 500;
    text-align: center;
    background: linear-gradient(1deg, #272631, #2a2933);
    border-top: 2px solid #32303f;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    padding: 15px;
    border-radius: 15px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        opacity: 0.8;
        color: #b5b5b5;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
    }
}

/* Dashboard Nfts Grades */
.gradeAlert {
    margin-bottom: 1em;
}

.gradeWidgetTabSelectedBronze {
    border-left: 3px solid #a15538;
    border-right: 3px solid #a15538;
}

.gradeWidgetTabSelectedSilver {
    border-left: 3px solid #8d8d8d;
    border-right: 3px solid #8d8d8d;
}

.gradeWidgetTabSelectedGold {
    border-left: 3px solid #a37c35;
    border-right: 3px solid #a37c35;
}

.gradeWidgetTabSelectedPlatinum {
    border-left: 3px solid #657885;
    border-right: 3px solid #657885;
}

/* Dashboard Nfts Grades Item */
.gradeItem {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.gradeItemSection {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #2c2c35;
    font-size: 0.85em;
    font-weight: 500;
    border-radius: 8px;
    padding: 25px 15px;
    margin: 0;

    &:not(:last-child) {
        margin-bottom: 7px;
    }

    ul {
        width: 100%;
        max-width: 270px;
        margin: 0 auto;
        padding: 0;
        list-style-type: none;

        li {
            display: flex;
            align-items: flex-start;
            gap: 6px;

            &:not(:last-child) {
                margin-bottom: 1em;
            }
            
            span {
                flex: 1;
            }

            .icon {
                margin-top: 2px;
            }
        }

        .sub {
            color: #81818f;

            &:not(:last-child) {
                margin-bottom: 0.5em;
            }
        }

        .content {
            flex: 1;
        }
    }
}
