.box {
    position: relative;
    background: #24242c;
    border: 1px solid #2d2d34;
    border-radius: 17px;
    box-shadow: 2px 4px 15px #0000003b;
    overflow: hidden;
    transition: all 0.5s ease-out;

    &:hover {
        border-color: #35353a;
    }
}

.boxMobile {
    border-radius: 0;
}

.boxRound {
    border-radius: 20px;
}

.boxClickable {
    &:hover {
        border-color: #43434a;
        box-shadow: 0 0 15px black;
    }
}

.boxContent {
    padding: 20px;
}

.titleWrap {
    display: flex;
    align-items: center;
    background: #292932;
    border-bottom: 1px solid #37373e;
    border-radius: 20px 20px 0 0;
    padding: 20px;
}

.title {
    flex: 1;
    font-size: 1.1em;
    font-weight: 500;
}

.titleCenter {
    text-align: center;
}

.closeButton {
    background: #201e26;
    color: #696972;
    padding: 7px;
    border-radius: 50%;
    margin-left: 20px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        color: #b5b5b5;
        background: #1b1920;
    }

    .icon {
        display: block;
        width: 23px;
        height: 23px;
    }
}

.footer {
    border-top: 1px solid #37373e;
    padding-top: 10px;
    margin-top: 20px;
}

.expandWrap {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 90px;
    background: linear-gradient(0deg, #2f2f3b, rgba(38, 38, 49, 0.9), transparent);
    backdrop-filter: blur(0.5px);
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
        box-shadow: inset 0px -20px 30px 0px #ffffff04;

        .expandContent {
            opacity: 1;
            //text-shadow: 0 0 60px #ffffff8c;
        }
    }
}

.exapndWrapOpen {
    pointer-events: none;
    opacity: 0;
}

.expandContent {
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 13px;
    width: 100%;
    font-weight: 500;
    font-size: 0.95em;
    padding: 15px;
    transition: all 0.2s ease-in-out;

    .logo {
        width: 13px;
        height: 13px;
    }
}