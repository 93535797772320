.cellSub {
    color: #636172;
    font-size: 0.8em;
    font-weight: 500;
    margin-top: 3px;
}

.cellMarkWrap {
    display: flex;
}

.cellMark {
    background: #141218;
    border-radius: 15px;
    padding: 4px 9px;
    font-size: 0.85em;
    font-weight: 500;
}

.cellMarkSuccess {
    color: #87b992;
}

.cellMarkFail {
    color: #b98787;
}

.featurePair {
    &:not(:last-child) {
        margin-bottom: 1em;
    }
}

/* Dashboard Invest */
.sectionLabel {
    display: flex;
    align-items: center;
    color: #afafaf;
    margin-top: 2.1em;
    margin-bottom: 10px;

    .icon {
        width: 40px;
        height: 40px;
        margin-right: 15px;
        margin-left: 6px;
    }

    .title {
        font-size: 1.05em;
        font-weight: 500;
        margin-bottom: 2px;
    }

    .titleSub {
        color: #616168;
        font-size: 0.85em;
        font-weight: 500;
    }
}

/* Dashboard Invest Info */
.infoDescription {
    font-size: 0.95em;
    font-weight: 500;
    color: #8d8d99;
    line-height: 1.3em;
    margin-bottom: 1em;

    a {
        display: inline-flex;
        align-items: center;
        gap: 4px;
        margin-left: 4px;
    }

    mark {
        font-weight: bold;
        background: linear-gradient(40deg, #a283b9, #8299bb);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        letter-spacing: 4px;
        font-size: 1.2em;
        font-style: italic;
        padding: 0 3px;
        text-shadow: 0 0 7px #758ec85c;
    }
}

.infoStats {
    display: flex;
    align-items: center;
    justify-content: stretch;
    gap: 10px;
    margin-bottom: 1em;
}

.infoStatsItem {
    flex: 1;
}

.infoWrap {
    border: 2px solid #201e26;
    background: #292932;
    border-radius: 12px;
    transition: all 0.5s ease-in-out;
    overflow: hidden;

    &:not(:last-child) {
        margin-bottom: 0.7em;
    }
}

.infoWrapOpen {
    border: 2px solid #201e26;
    background: #292932;
}

.infoTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
    font-size: 0.9em;
    font-weight: 500;
    padding: 15px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;

    .arrow {
        transition: all 0.5s ease-in-out;
    }

    mark {
        color: #7f70b2;
        background: none;
        font-size: 1.2em;
        font-style: italic;
        border-bottom: 1px solid #7f70b2;
        font-weight: bold;
        text-shadow: 0 0 1px #000000;
        margin-right: 1px;
    }
}

.infoTitleOpen {
    .arrow {
        transform: rotate(180deg);
    }
}

.infoContent {
    border-top: 2px solid #201e26;
    overflow: hidden;
}

.infoGroupContent {
    color: #696972;
    font-size: 0.85em;
    font-weight: 500;
    line-height: 1.3em;
    padding: 15px;
}

/* Dashboard Invest Campaigns */
.campaignItem {
    &:not(:last-child) {
        margin-bottom: 1em;
    }
}

.campaignViewCompleted {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #7c7c7c;
    font-size: 0.95em;
    font-weight: 500;
    text-align: center;
    background: linear-gradient(1deg, #272631, #2a2933);
    border-top: 2px solid #32303f;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    padding: 15px;
    border-radius: 15px;
    margin-top: 1em;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        opacity: 0.8;
        color: #b5b5b5;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
    }
}

/* Dashboard Invest Campaigns Item */
.campaignWrap {
    padding: 0;
}

.campaignAlertWrap {
    padding: 15px;
    border-bottom: 2px dashed #282831;
}

.campaignAlertContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .title {
        font-weight: 500;
    }

    .subtitle {
        font-size: 0.9em;
        font-weight: 500;
        color: #696972;
        margin-top: 2px;
    }
}

.campaignInfoWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 15px;
}

.campaignInfoPair {
    gap: 4px;
}

.campaignInfoTitle {
    display: flex;
    align-items: center;
    gap: 7px;
}

.campaignInvestButton {
    width: 110px;
}

.campaignProgressWrap {
    padding: 15px;
    border-top: 2px dashed #282831;
}

.campaignProgressPair {
    margin-top: 7px;
}

.campaignProgressPairValue {
    color: #616168;
    font-size: 0.85em;
    font-weight: 500;
}

/* Dashboard invest campaigns invest */
.addFundsAlert {
    margin-bottom: 1em;
}

.addFundsDescription {
    color: #8d8d99;
    font-size: 0.95em;
    font-weight: 500;
    line-height: 1.5em;
    margin-bottom: 1em;
}

.addFundsSettings {
    margin-bottom: 1em;
}

.addFundsButtonGroup {
    display: flex;
    align-items: center;
    gap: 10px;
}

.addFundsButton {
    flex: 1;
}

/* Dashboard Invest Funds */
.fundsOverview {
    display: flex;
    align-items: center;
    justify-content: stretch;
    gap: 10px;
    margin-bottom: 1em;
}

.fundsOverviewItem {
    flex: 1;
}

.fundItem {
    &:not(:last-child) {
        margin-bottom: 1em;
    }
}

/* Dashboard Invest Funds Item */
.fundWrap {
    padding: 0;
}

.fundAlertWrap {
    padding: 15px;
    border-bottom: 2px dashed #282831;
}

.fundInfoWrap {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px;
    border-bottom: 2px dashed #282831;
}

.fundInfoPair {
    flex: 1;
    gap: 2px;
}

.fundInfoPairTitle {
    display: flex;
    align-items: center;
    gap: 4px;
}

.fundInfoTitle {
    display: flex;
    align-items: center;
    gap: 7px;
}

.fundsButton {
    width: 45px;
    color: #8d8d99;

    .icon {
        width: 23px;
        height: 23px;
    }
}

.fundDetailsWrap {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px;
}

.fundDetailsPair {
    flex: 1;

    &:not(:first-child) {
        border-left: 1px solid #37373e;
        padding-left: 20px;
    }
}

.fundDetailsValue {
    display: flex;
    align-items: center;
    gap: 4px;
}

.fundDetailsValueExtra {
    color: #8d8d99;
    font-size: 0.8em;
    font-weight: 500;
    background: #2f2f38;
    border-radius: 15px;
    padding: 2px 5px;
}

/* Dashboard invest Funds Withdraw */
.withdrawFundsAlert {
    margin-bottom: 1em;
}

.withdrawFundsDescription {
    color: #8d8d99;
    font-size: 0.95em;
    font-weight: 500;
    line-height: 1.5em;
    margin-bottom: 1em;
}

.withdrawFundsSettings {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 1em;
}

.withdrawFundsButtonGroup {
    display: flex;
    align-items: center;
    gap: 10px;
}

.withdrawFundsButton {
    flex: 1;
}

/* Dashboard invest Funds Withdraw Confirm */
.withdrawFundsCheck {
    align-items: flex-start !important;
    margin-bottom: 1em;
    margin-left: 7px !important;
}

.withdrawFundsCheckLabel {
    color: #8d8d99 !important;
    font-size: 0.95em !important;
    font-weight: 500 !important;
    font-family: inherit !important;
}

.withdrawFundsCheckBox {
    padding: 0 !important;
    padding-right: 7px !important;
}