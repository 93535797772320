.sectionItem {
    position: relative;
    padding-left: 30px;
    padding-bottom: 5px;
    
    &:before {
        content: '';
        position: absolute;
        top: 15px;
        left: 15px;
        width: 12px;
        height: 12px;
        background: #404148;
        border-radius: 50%;
    }

    &:after {
        content: '';
        position: absolute;
        top: 15px;
        left: 20px;
        width: 2px;
        height: calc(100% - 25px);
        background: #404148;
        border-radius: 10px;
    }
}

.sectionTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #8d8d99;
    font-size: 0.85em;
    font-weight: 500;
    padding: 12px 15px 0 15px;
}

.sectionContent {
    padding: 12px 15px;
    overflow: hidden;
    color: #696972;
    font-size: 0.8em;
    font-weight: 500;
}