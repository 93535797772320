.dialog {
    color: inherit !important;
    border-radius: 20px !important;
}

.dialogMobile {
    align-self: end !important;
    width: 100% !important;
    color: inherit !important;
    margin: 0 !important;
}

.dialogContent {
    background: #201e26;
    padding: 20px;
}

.dialogContentMobile {
    padding: 0;
}

.dialogContainer {
    backdrop-filter: blur(9px);
}

