.goUp {
    display: inline-flex;
    opacity: 0;
    transform: translate3d(0, -20px, 0);
    transition: transform 0.1s ease-in-out;
}

.waitDown {
    display: inline-flex;
    opacity: 0;
    transform: translate3d(0, 20px, 0);
}

.initial {
    display: inline-flex;
    opacity: 1;
    transform: translate3d(0, 0px, 0);
    transition: transform 0.1s ease-in-out;
}
